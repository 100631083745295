<template>
  <div class="LsrPlatformDetail">
    <ContainerCard :locationMsg="locationMsg" :width="65">
      <div class="titleInfo">
        <div class="groupRight">
          <span class="title1">{{ infoData.name }}</span>
          <div class="title2">
            <div class="top">
              <span class="word-btn">{{ infoData.level }}</span>
            </div>
          </div>
          <div>
            <span class="word">
                <span class="label">建设主体</span>
                <span class="value">{{ infoData.mainbody }}</span>
          </span>
          </div>
        </div>
        <div class="groupLeft">
          <el-image
            class="img1"
            referrerpolicy="no-referrer"
            v-if="infoData.photo"
            :src="httpConfig.baseURL+infoData.photo"
          />
        </div>

        <div class="no-imgbox" v-if="!infoData.photo">
              <el-image class="no-img" :src=" defaultImg " alt="" />
        </div>
      
      </div>
      <div class="inline"></div>

      <!-- <div class="info">
        <div class="info-value">
          <div class="title2">
            <div class="top">
              <span class="word">
                <span class="label">建设主体</span>
                <span class="value">{{ infoData.mainbody }}</span>
              </span>
              <span class="word">
                <span class="label">联系人</span>
                <span class="value">{{ infoData.contact }}</span>
              </span>
            </div>
            <div class="top">
              <span class="word">
                <span class="label">地址</span>
                <span class="value">{{ infoData.address }}</span>
              </span>
              <span class="word">
                <span class="label">联系电话</span>
                <span class="value">{{ infoData.mobile }}</span>
              </span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="inline"></div> -->
      <!-- <div  class="info">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">技术领域</span>
        </div>
        <div
          class="info-item"
          v-for="(item, index) in infoData.techList"
          :key="index"
        >
          {{ item }}
        </div>
      </div> -->
      <!-- <div class="inline"></div> -->
      <div class="info">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">产学研平台描述</span>
        </div>
        <div class="info-value">{{infoData.memo}}</div>
      </div>
      <div class="inline"></div>
      <div class="info" style="padding-bottom: 0px">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">合作咨询</span>
        </div>
        <div class="info-consulting">
          <div class="tit">福建省产教融合综合服务平台</div>
          <div class="consulting-item">
            <!-- <span class="label">联系地址</span> -->
            <span class="label">联系人</span>
            <span class="value"
              >{{ infoData.contact }}</span
            >
          </div>
          <div class="consulting-item">
            <span class="label">联系电话</span>
            <span class="value">{{ infoData.mobile }}</span>
          </div>
          <div class="consulting-item">
            <!-- <span class="label">联系邮箱</span> -->
            <span class="label">地址</span>
            <span class="value">{{ infoData.address }}</span>
          </div>
        </div>
      </div>
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Consult from "../../components/Consult.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import { toPageByid } from "../../api/SixLibraries/LsrPlatform/LsrPlatform.js";
import { objDeepCopy } from "../../common/utils/tool";
import Contact from "../../components/SixLibraries/Contact.vue";
import { mapGetters } from "vuex";
import { getPlainText } from "../../common/utils/tool";
import httpConfig from "../../common/utils/config";
import defaultImg from "../../assets/images/暂无数据默认图片.png";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      defaultImg,
      httpConfig,
      infoData: {},
      type: [
        {
          label: "全部",
          value: "all",
        },
        { label: "国家级", value: "1" },
        { label: "省级", value: "2" },
        { label: "市级", value: "3" },
        { label: "县级", value: "4" },
        { label: "校级", value: "5" },
        { label: "其他", value: "6" },
      ],
      locationMsg: [
        { name: "七大库"},
        { name: "产学研平台库", router: "LsrPlatform" },
        { name: "平台详情", router: "LsrPlatform/LsrPlatformDetails" },
      ],
    };
  },
  components: { Contact, ContainerCard, Location, Consult },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  methods: {
    async getData(id) {
      let that = this;
      let res = await toPageByid({ id: id });
      let tmp = objDeepCopy(res.data);
      const regex = new RegExp("<img", "gi");
      tmp.memo =
        res.data?.memo &&
        res.data?.memo.replace(regex, '<img style="max-width: 100%;"');
      tmp.techList = [];
      this.type.find((item) => {
        if (tmp.level.toString() === item.value.toString()) {
          tmp.level = item.label;
        }
      });
      Object.keys(that.techfieldList).forEach(function (key) {
        if (
          tmp?.tech_field &&
          tmp?.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
        ) {
          tmp.techList.push(key);
        }
      });
      tmp.memo = getPlainText(getPlainText(tmp.memo));
      this.infoData = tmp;
    },
  },
};
</script>
<style scoped lang="scss">
.titleInfo {
  width: 756px;
  height: 158px;
  padding: 24px 30px 24px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .groupLeft {
    margin-right: 23px;
    z-index: 126;
    // width: 110px;
    // height: 110px;
    .img1 {
      z-index: 127;
      width: 184px;
      height: 110px;
      border-radius: 6px;
    }
  }
  .no-imgbox {
        // margin-right: 30px;
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
  .groupRight {
    .title1 {
      width: 112px;
      height: 28px;
      color: rgba(16, 16, 16, 1);
      font-size: 24px;
      font-family: Bold;
      white-space: nowrap;
      line-height: 36px;
      text-align: left;
      font-weight: 600;
      // margin-left: 3px;
      margin-bottom: 12px;
    }

    .title2 {
      margin-bottom: 15px;
      @mixin title2Font {
        font-size: 12px;
        font-family: Regular;
        line-height: 18px;
        color: #8896a7;
      }
      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .word {
          .label {
            @include title2Font;
            margin-right: 12px;
          }
          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
        .word-btn {
          padding:3px 5px ;
          box-sizing: border-box;
          color: #FFFFFF;
          line-height: 18px;
          font-family: Regular;
          background-color: #2885FF;
          border-radius: 3px;
          margin-top: 12px
        }
      }
      .bottom {
        .wordBottom {
          .label {
            @include title2Font;
            margin-right: 12px;
          }

          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
      }
    }
    // .word {
    //   //   width: 178px;
    //   height: 16px;
    //   display: block;
    //   overflow-wrap: break-word;
    //   color: rgba(16, 16, 16, 1);
    //   font-size: 16px;
    //   font-family: Regular;
    //   white-space: nowrap;
    //   line-height: 16px;
    //   text-align: left;
    //   margin-top: 16px;
    // }
    .word {
          .label {
            font-size: 12px;
            font-family: Regular;
            line-height: 18px;
            color: #8896a7;
            margin-right: 12px;
          }
          .value {
            font-size: 12px;
            font-family: Regular;
            line-height: 18px;
            color: #404040;
            font-weight: 500;
          }
      }
    .wordBottom {
      //   width: 338px;
      height: 16px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      font-family: Regular;
      white-space: nowrap;
      line-height: 16px;
      text-align: left;
      margin-top: 8px;
    }
  }
}
.inline {
  //   width: 100%;
  height: 1px;
  width: 700px;
  background-color: rgba(216, 216, 216, 1);
  // margin-left: 30px;
  // margin-top: 24px;
}
.info {
  width: 756px;
  box-sizing: border-box;
  padding: 24px 30px 24px 0px;
  .info-title {
    margin-bottom: 12px;
    img {
      width: 8px;
      height: 10px;
      margin-right: 12px;
    }
    .label {
      font-size: 16px;
      font-family: Medium;
      color: #101010;
      line-height: 24px;
    }
  }
  .info-item {
    display: inline-block;
    border-radius: 34.5px;
    padding: 10px 16px;
    border: 1px solid #f1f1f1;
    color: #101010;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background-color: #fafafa;
    font-family: Regular;
  }
  .info-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #404040;
    font-family: 思源黑体 CN Regular;
    text-align: justify; //两端对齐
    .title2 {
      @mixin title2Font {
        font-size: 12px;
        font-family: Regular;
        line-height: 18px;
        color: #8896a7;
      }
      .top {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        .word {
          flex: 1;
          // margin-right: 300px;
          .label {
            @include title2Font;
            margin-right: 12px;
          }
          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
      }
      .bottom {
        .wordBottom {
          .label {
            @include title2Font;
            margin-right: 12px;
          }

          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
      }
    }
  }

  .info-consulting {
    .tit {
      background-color: #2885ff;
      padding: 6px;
      box-sizing: border-box;
      display: inline-block;
      border-radius: 2px 8px 8px 8px;
      color: #ffffff;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 20px;
    }
    .consulting-item {
      margin-bottom: 20px;
      .label {
        font-size: 12px;
        line-height: 18px;
        color: #8896a7;
        margin-right: 12px;
      }

      .value {
        font-size: 12px;
        line-height: 18px;
        color: #404040;
      }
    }
  }
}
.info {
  border-radius: 5px;
  // margin-bottom: 20px;
  // margin-top: 24px;
  .infoTop {
    display: flex;
    justify-content: flex-start;
  }
  .logo {
    width: 268px;
    height: 184px;
    margin-right: 20px;
  }
  .intros {
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    .name {
      font-size: 28px;
      font-family: Medium;
      font-weight: 500;
      color: #101010;
    }
    .labelGroup {
      margin-top: 20px;
      width: 100%;
      display: flex;
      .item {
        margin-right: 8px;
        list-style: none;
        border-radius: 8px;
        border: 1px solid #3e9cff;
        padding: 7px 10px;
        font-size: 14px;
        font-family: Regular;
        font-weight: 400;
        color: #3e9cff;
      }
    }
    .intro {
      width: 100%;
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      > p {
        margin-top: 20px;
        font-size: 16px;
        font-family: Regular;
        font-weight: 400;
        color: #101010;
        width: 50%;
      }
    }
  }
  .lineBox {
    margin-top: 24px;
    width: 100%;
    height: 1px;
    background: #d8d8d8;
  }
  .infomain {
    margin-top: 24px;
    .title {
      font-size: 28px;
      font-family: Regular;
      font-weight: 400;
      color: #101010;
    }
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: Regular;
      font-weight: 400;
      color: #101010;
      letter-spacing: 1px;
    }
  }
}
::v-deep .locationList{
  position: absolute;
  left: -27px;
}
@media screen and (max-width: 768px) {
}
</style>